import React, { useState} from 'react'
import {
  Route,
  Redirect
} from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
import 'firebase/firestore';
import Logout from './Logout'
// import GoogleButton from 'react-google-button' // optional

import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'

function LoginPage () {

  const [user, changeUser] = useState({
    fullName: '',
    email: '',
    password: '',
    agreement: false
  })
  const [agreementAlert, changeAgreementAlert] = useState(false);
  const [errorMssg, changeErrorMssg] = useState(null);
  const firebase = useFirebase()

  const auth = useSelector(state => state.firebase.auth)

  const createNewUser = () => {
    console.log(user)
    let fullName = user.fullName;
    let email = user.email;
    let password = user.password;
    
    changeErrorMssg(null)
    
    changeAgreementAlert(!user.agreement);
    if (!user.agreement) {
      console.log("blocked")
      return;
    }

    firebase.createUser(
      { email, password },
      { fullName, email }
    )
    .then(user => {
      console.log("User");
      console.log(user);
      // Set email verification event.
      firebase.auth().onAuthStateChanged(function(user) {
        console.log("Verification email sent")
        user.sendEmailVerification(); 
      });
    })
    .catch(err => {
      console.log(err)
      changeErrorMssg(err.message)
    });
  }

  function resetInput() {
    changeErrorMssg(null)
    changeUser({
      email: '',
      password: '',
      fullName: '',
      agreement: false
    })
  }

  function onAgreement(e) {
    //return changeInput(e && e.target && e.target.value)
    let u = user;
    return changeUser({
      email: u.email,
      password: u.password,
      fullName: u.fullName,
      agreement: !u.agreement
    })
  }

  function onInputChange(e) {
    //return changeInput(e && e.target && e.target.value)
    let u = user;
    u[e.target.name] = (e && e.target && e.target.value)
    console.log(e.target.value)
    return changeUser({
      email: u.email,
      password: u.password,
      fullName: u.fullName,
      agreement: u.agreement
    })
  }



  return (
    <div>
      {
        !isLoaded(auth)
        ? <span>Loading...</span>
        : isEmpty(auth)
        ?
        <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h3>Signup</h3>
          <legend></legend>
          {
            (errorMssg !== null) ?
            <Alert variant={"warning"}>
              {errorMssg}
            </Alert> : ""
          }
          <Form>
            <Form.Group controlId="formFullName">
              <Form.Label>Full name</Form.Label>
              <Form.Control type="text" name="fullName" placeholder="Full name" value={user.fullName} onChange={onInputChange} />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" placeholder="Enter email" value={user.email} onChange={onInputChange} />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" placeholder="Password" value={user.password} onChange={onInputChange} />
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox">
                <Form.Check.Input type="checkbox" name="agreement" defaultChecked={user.agreement} onClick={onAgreement}></Form.Check.Input>
                <Form.Check.Label>
                  I have read and accept the <a href="/privacy" target="_blank">privacy policy</a> and <a href="/terms" target="_blank">terms of use</a>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            {
              agreementAlert ?
              <Alert variant={"warning"}>
                Please read and accept the <a href="/privacy" target="_blank">privacy policy</a> and <a href="/terms" target="_blank">terms of use</a> before proceeding to signup.
              </Alert> : ""
            }
            <Button onClick={createNewUser} variant="primary">
              Sign up
            </Button> <a href="/login">Login</a>
          </Form>
        </Col>
        </Row>
        : <div>
        {
          <Redirect
            to={{
                pathname: "/"
            }}
          />
        }
      </div>
      }
    </div>
  )
}

export default LoginPage