import React, { useState } from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/app'
import { useSelector } from 'react-redux'
import { useFirebase, useFirestoreConnect } from 'react-redux-firebase'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import Switch from "react-switch";

import { FaRegCopy } from 'react-icons/fa';
import Clipboard from 'react-clipboard.js';
import TrayHelper from './TrayHelper'

import mixpanel from 'mixpanel-browser';

export default function AddTray() {
    const profile = useSelector(state => state.firebase.profile)
    const [label, changeLabel] = useState('')
    const [active, changeActive] = useState(true)
    const [alias, changeAlias] = useState(null)
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        mixpanel.track("LoadAliasGenerator")
    }
    function resetInput() {
        changeLabel('')
        changeActive(true)
        changeAlias(null)
        setIsLoading(false)
        setShow(false);
    }

    function copyToClipboard () {
        navigator.clipboard.writeText(alias);
    }

    function onInputChange(e) {
        return changeLabel(e && e.target && e.target.value)
    }

    function AddTrayFunc () {
        setIsLoading(true);
        const AddTrayFunc = firebase.functions().httpsCallable('generate');
        AddTrayFunc({name: label, active: active}).then((result) => {
            //resetInput();
            changeAlias(TrayHelper.key2Alias(result.data.alias));
            mixpanel.track("SucceededAliasGenerator")
        }).catch((error) => {
            mixpanel.track("FailedAliasGenerator")
            console.log(`error: ${JSON.stringify(error)}`);
        });
        mixpanel.track("AttemptAliasGenerator")
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Generate Alias
            </Button>

            <Modal show={show} onHide={resetInput}>
                <Modal.Header closeButton>
                <Modal.Title>Generate Alias</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Where do you plan to use this alias? Example: Netflix</Form.Label>
                        <Form.Control value={label} onChange={onInputChange} type="text" placeholder="Alias name" />
                    </Form.Group>
                    <Form.Group>
                        <Switch
                        onChange={changeActive}
                        checked={active}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={40}
                        />
                        <Form.Text className="text-muted">
                            { active ? "Generate activated alias and start receiving emails." : "Generate a deactive alias. Emails will bounce until alias is activated." }
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Alert variant={"dark"} className="text-center">
                            {
                                alias !== null ?
                                alias :
                                <div><span style={{color: "#777"}}>███████</span>@mail.dopemin.com</div>
                            }
                        </Alert>
                        <Form.Text className="text-muted">
                            Emails sent to this address with be forwarded to {profile.email}
                        </Form.Text>
                    </Form.Group>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {
                    (alias === null) ?
                    <Button variant="primary" onClick={!isLoading ? AddTrayFunc : null} disabled={isLoading || label === ''}>
                        {
                            isLoading ?
                            <span><Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            /> Generating...</span>
                            :
                            "Generate"
                        }
                    </Button> :
                    <Clipboard className="btn btn-primary" data-clipboard-text={alias} >
                        <FaRegCopy/> Copy alias to clipboard
                    </Clipboard>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}