import React, { useState} from 'react'
import {
  Route,
  Redirect
} from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
import 'firebase/firestore';
import Logout from './Logout'
// import GoogleButton from 'react-google-button' // optional

import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'

function LoginPage () {

  const [user, changeUser] = useState({
    email: '',
    password: ''
  })
  const [errMssg, setErrMssg] = useState(null);
  const firebase = useFirebase()

  const auth = useSelector(state => state.firebase.auth)


  function login() {
    setErrMssg(null);
    console.log(user)
    let email = user.email;
    let password = user.password;

    firebase.login(
      { email, password }
    )
    .then(user => {
      console.log("User");
      console.log(user);
    })
    .catch((err) => {
      console.log(err)
      let errorMssg = "Invalid username or password."
      if (err.code == "auth/user-disabled") {
        errorMssg = "User account activation is pending. New accounts are activated every week. Watchout for an email notification on activation."
      }
      setErrMssg(errorMssg)
    });
  }

  function resetInput() {
    changeUser({
      email: '',
      password: ''
    })
  }
  function onInputChange(e) {
    //return changeInput(e && e.target && e.target.value)
    let u = user;
    u[e.target.name] = (e && e.target && e.target.value)
    return changeUser({
      email: u.email,
      password: u.password
    })
  }



  return (
    <div>
      {
        !isLoaded(auth)
        ? <span>Loading...</span>
        : isEmpty(auth)
        ?
        <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h3>Login</h3>
          <legend></legend>
          {
            errMssg != null ?
            <Alert variant="danger">
              {errMssg}
            </Alert>
            : <span></span>
          }
          <Form>
            <Form.Group controlId="formGroupEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" placeholder="Enter email" value={user.email} onChange={onInputChange} />
            </Form.Group>
            <Form.Group controlId="formGroupPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" placeholder="Password" value={user.password} onChange={onInputChange} />
            </Form.Group>
            <Button onClick={login} variant="primary">
              Login
            </Button> <a href="/signup">Sign up</a>
          </Form>
        </Col>
        </Row>
        : <div>
        {
          auth.emailVerified
          ? <Redirect
          to={{
            pathname: "/"
          }}
        />
        : <span>Please verify your email address before you proceed. An email has been sent.</span>
        }
      </div>
      }
    </div>
  )
}

export default LoginPage