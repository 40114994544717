const keys = [
    "a",
    "b"
]
const domains = [
    "relay.omkarslab.com",
    "mail.dopemin.com"
]

exports.alias2Key = function (alias) {
    try {
        let s = alias.split('@');
        let index = s.length == 2 ? domains.indexOf(s[1]) : -1
        if (index == -1) {
            throw "Domain mismatch " + s[1]
        }
        return s[0] + "_" + keys[index]
    }
    catch (err) {
        throw "Invalid alias: " + err
    }
}


// Key to Alias
exports.key2Alias = function (key) {
    try {
        let s = key.split('_');
        let index = s.length == 2 ? keys.indexOf(s[1]) : -1
        if (index == -1) {
            throw "Domain mismatch " + s[1]
        }
        return s[0] + "@" + domains[index]
    }
    catch (err) {
        throw "Invalid alias: " + err
    }
}