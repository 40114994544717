import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import { useFirebase, useFirestoreConnect } from 'react-redux-firebase'
import Card from 'react-bootstrap/Card';
import Switch from "react-switch";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Clipboard from 'react-clipboard.js';
import { FaRegCopy } from 'react-icons/fa';

import TrayHelper from './TrayHelper'
// import './Todo.css'


function Tray({ id }) {
  const todo = useSelector(({ firestore: { ordered } }) => ordered.trays && ordered.trays[id])
  const auth = useSelector(state => state.firebase.auth)

  const firestore = useFirestore()

  function updateStatus (s) {
    firestore.update(`trays/${todo.id}`, { active: s, owner: auth.uid})
  }

  return (
    <Card style={{ marginTop: '10px' }}>
      <Card.Body>
        <Card.Title>
          <Row>
            <Col>{todo.name}</Col>
            <Col>
              <div className="d-flex justify-content-end">
                <Switch
                  onChange={updateStatus}
                  checked={todo.active}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={40}
                />
              </div>
            </Col>
          </Row>
        </Card.Title>
        <Card.Subtitle className="text-muted">
          {TrayHelper.key2Alias(todo.id)}
          <Clipboard className="badge badge-light" style={{marginLeft:"5px"}} data-clipboard-text={TrayHelper.key2Alias(todo.id)} >
            <FaRegCopy/>
          </Clipboard>
        </Card.Subtitle>
      </Card.Body>
    </Card>
  )
}

Tray.propTypes = {
  id: PropTypes.string.isRequired
}

export default Tray