import React, { Button } from 'react'
import { render } from 'react-dom'
import { Provider, useSelector } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore' // <- needed if using firestore
import 'firebase/functions' // <- needed if using httpsCallable
import { createStore, combineReducers, compose } from 'redux'
import { ReactReduxFirebaseProvider, firebaseReducer, isLoaded, isEmpty } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore
import { firebase as fbConfig, reduxFirebase as rfConfig } from './config'

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Spinner } from 'react-bootstrap';
import Login from './Login'
import Signup from './Signup'
import Terms from './Legal/Terms'
import Privacy from './Legal/Privacy'

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Trays from './Trays'

import mixpanel from 'mixpanel-browser';

// Initialize firebase instance
firebase.initializeApp(fbConfig)

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore
firebase.functions() // <- needed if using httpsCallable

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer // <- needed if using firestore
})

// Create store with reducers and initial state
const initialState = {}
const store = createStore(rootReducer, initialState)

const rrfProps = {
  firebase,
  config: rfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return (
    <Container style={{minHeight: "100%"}}>
      <div className="cssload-thecube" style={{marginTop:"30%"}}>
        <div className="cssload-cube cssload-c1"></div>
        <div className="cssload-cube cssload-c2"></div>
        <div className="cssload-cube cssload-c4"></div>
        <div className="cssload-cube cssload-c3"></div>
      </div>
    </Container>
  );
  
  return children
}

function logout () {
  firebase.logout()
}

function NavProfile () {
    const profile = useSelector(({ firebase: { profile } }) => profile)
    if (profile.isEmpty) return (<div></div>)
    return (
      <div>
        <div>
          Signed in as: <a href="#">{profile.fullName}</a> | <a href="#" onClick={logout}>Logout</a>
        </div>
      </div>
    )
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
function PrivateRoute({ children, ...rest }) {
  const auth = useSelector(state => state.firebase.auth)
  console.log("Auth")
  console.log(auth);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) && auth.emailVerified ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}



// Setup react-redux so that connect HOC can be used
function App() {
  mixpanel.track("Home");
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
      <Container>
      <Navbar>
        <Navbar.Brand href="#home">
          <img src="hider.png" height="40"/>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <NavProfile/>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
      <AuthIsLoaded>
      <BrowserRouter>
          <Switch>
            <Route path="/login">
              {/* Component containing a login which redirects
              to /protected. NOTE: Not included in example */}
              <Login/>
            </Route>
            <Route path="/signup">
              {/* Component containing a login which redirects
              to /protected. NOTE: Not included in example */}
              <Signup />
            </Route>
            <Route path="/terms">
              <Terms/>
            </Route>
            <Route path="/privacy">
              <Privacy/>
            </Route>
            <PrivateRoute path="/">
              <Trays />
            </PrivateRoute>
          </Switch>
        </BrowserRouter>
        </AuthIsLoaded>
        </Container>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

// render(<App />, document.getElementById('root'))

export default App;
