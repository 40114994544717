import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useFirebase, useFirestoreConnect } from 'react-redux-firebase'

import firebase from 'firebase/app'

function logout () {
    firebase.logout()
}

function Logout() {
  return (
    <button onClick={logout}>Logout</button>
  )
}


export default Logout