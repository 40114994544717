export const firebase = {
    apiKey: "AIzaSyBJd2-bKJhTP02GoY6aoAoOnRe84gF3Mbo",
    authDomain: "pigeon-ae4fb.firebaseapp.com",
    databaseURL: "https://pigeon-ae4fb.firebaseio.com",
    projectId: "pigeon-ae4fb",
    storageBucket: "pigeon-ae4fb.appspot.com",
    messagingSenderId: "316685545318",
    appId: "1:316685545318:web:a425ee5de3ae1fca0f22a7",
    measurementId: "G-J1XCXK8JC9"
  }
  
  export const reduxFirebase = {
    userProfile: 'users',
    useFirestoreForProfile: true,
    enableLogging: false
  }
  
  export default { firebase, reduxFirebase }
  