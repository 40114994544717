import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useFirebaseConnect, useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import Tray from './Tray'
import AddTray from './AddTray'
import firebase from 'firebase/app'

import Container from 'react-bootstrap/Container'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Form from 'react-bootstrap/Form'

import mixpanel from 'mixpanel-browser';

export default function Trays() {

  const auth = useSelector(state => state.firebase.auth)
  const [searchTerm, changeSearchTerm] = useState('')

  useFirestoreConnect([
    { 
      collection: 'trays',
      where: [
        ['owner', '==', auth.uid]
      ]
    } // or 'todos'
  ])

  function onSearchTermUpdate (e) {
    let c = changeSearchTerm(e && e.target && e.target.value)
    return c
  }

  /**
   * Return between 0 and 1 the match rating
   */
  function containsTerm (term, target) {
    term = term.toLowerCase().trim();
    target = target.toLowerCase();

    // Validate against full target
    if (target.indexOf(term) !== -1) {
      return 1;
    }

    // Validate tokenized
    let t = target.split(" ")
    for (let i = 0; i < t.length; i++) {
      if (t[i].indexOf(term) !== -1) {
        return 1;
      }
    }
    return 0;
  }

  function isValidAgainstSearch (term, t) {
    if (searchTerm == "") {
      return true;
    }
    
    if (containsTerm(term, t.name) > 0) {
      return true;
    }

    if (containsTerm(term, t.id) > 0) {
      return true;
    }
    return false;
  }
  
  const trays = useSelector(state => state.firestore.ordered.trays)

  if (!isLoaded(trays)) {
    return (
      <Container style={{minHeight: "100%"}}>
      <div className="cssload-thecube" style={{marginTop:"30%"}}>
        <div className="cssload-cube cssload-c1"></div>
        <div className="cssload-cube cssload-c2"></div>
        <div className="cssload-cube cssload-c4"></div>
        <div className="cssload-cube cssload-c3"></div>
      </div>
    </Container>)
  }

  if (isEmpty(trays)) {
    return (
      <Container>
      <div>
        <h1>Aliases</h1>
      </div>
      <AddTray/>
      <div>
        <i>No aliases created...</i>
      </div>
      </Container>
    )
  }

  return (
    <Container>
      <div>
      <h1>Aliases</h1>
      </div>
      <AddTray/>
      <legend></legend>
      <Form>
        <Form.Group>
            <Form.Control value={searchTerm} onChange={onSearchTermUpdate} type="text" placeholder="Search" />
        </Form.Group>
      </Form>
      <legend></legend>
      <Tabs defaultActiveKey="active" id="uncontrolled-tab-example">
        <Tab eventKey="active" title="Active">
        {
          Object.keys(trays).map(
            (key, id) => (
              (trays[id].active && isValidAgainstSearch(searchTerm, trays[id])) ?
              <Tray key={key} id={id.toString()} />:
              ""
            )
          )
        }
        </Tab>
        <Tab eventKey="inactive" title="Inactive">
        {
          Object.keys(trays).map(
            (key, id) => (
              (!trays[id].active && isValidAgainstSearch(searchTerm, trays[id])) ?
              <Tray key={key} id={id.toString()} />:
              ""
            )
          )
        }
        </Tab>
      </Tabs>
    </Container>
  )
}